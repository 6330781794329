<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
        id="kt_wizard_v3"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>1</span> Umumiy malumotlar</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center px-8 px-lg-10">
          <div class="col-12">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <!-- <h3 class="font-weight-bold text-dark">
                  User's Profile Details:
                </h3> -->
                <div class="row flex-wrap">
                  <div class="col-3 align-self-end">
                    <div
                      class="image-input image-input-outline"
                      id="kt_contact_add_avatar"
                    >
                      <div
                        class="image-input-wrapper"
                        style="
                          background-image: url(assets/media/users/100_2.jpg);
                        "
                      ></div>
                      <label
                        class="
                          btn
                          btn-xs
                          btn-icon
                          btn-circle
                          btn-white
                          btn-hover-text-primary
                          btn-shadow
                        "
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".jpg, .jpeg"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span
                        class="
                          btn
                          btn-xs
                          btn-icon
                          btn-circle
                          btn-white
                          btn-hover-text-primary
                          btn-shadow
                        "
                        data-action="cancel"
                        data-toggle="tooltip"
                        title="Cancel avatar"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div>
                      <v-text-field
                        label="Ism"
                        v-model="first_name"
                        :error-messages="first_nameErrors"
                        @input="$v.first_name.$touch()"
                        @blur="$v.first_name.$touch()"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <div class="col-3">
                    <v-text-field
                      v-model="last_name"
                      :error-messages="last_nameErrors"
                      @input="$v.last_name.$touch()"
                      @blur="$v.last_name.$touch()"
                      label="Familiya"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-3">
                    <v-text-field
                      v-model="father_name"
                      :error-messages="father_nameErrors"
                      @input="$v.father_name.$touch()"
                      @blur="$v.father_name.$touch()"
                      label="Otasining ismi"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>

                  <div class="col-3">
                    <div>
                      <v-autocomplete
                        v-model="oblastSelect"
                        :error-messages="oblastSelectErrors"
                        @input="$v.oblastSelect.$touch()"
                        @blur="$v.oblastSelect.$touch()"
                        :items="Oblast"
                        label="Oblast"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-object
                        dense
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="col-3">
                    <div>
                      <v-autocomplete
                        v-model="regionSelect"
                        :error-messages="regionSelectErrors"
                        @input="$v.regionSelect.$touch()"
                        @blur="$v.regionSelect.$touch()"
                        :items="oblastSelect.oblast_region"
                        label="Region"
                        outlined
                        item-text="name"
                        item-value="id"
                        dense
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="col-3">
                    <div>
                      <v-text-field
                        label="Yashash manzili"
                        v-model="pochta_address"
                        :error-messages="pochta_addressErrors"
                        @input="$v.pochta_address.$touch()"
                        @blur="$v.pochta_address.$touch()"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="col-3">
                    <v-text-field
                      v-model="phone_number"
                      :error-messages="phone_numberErrors"
                      @input="$v.phone_number.$touch()"
                      @blur="$v.phone_number.$touch()"
                      label="Telefon raqam"
                      placeholder="(XX) XXX XX XX"
                      v-mask="'(##) ### ## ##'"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-3">
                    <v-text-field
                      label="Pasport raqami"
                      v-mask="'AA#######'"
                      placeholder="AB1234567"
                      v-model="passport_number"
                      :error-messages="passport_numberErrors"
                      @input="$v.passport_number.$touch()"
                      @blur="$v.passport_number.$touch()"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                  <!-- dd -->

                  <div class="col-3">
                    <div>
                      <v-text-field
                        label="Pnfl"
                        v-model="pinfl"
                        :error-messages="pinflErrors"
                        @input="$v.pinfl.$touch()"
                        @blur="$v.pinfl.$touch()"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-3">
                    <div>
                      <v-text-field
                        label="Inn"
                        v-model="inn"
                        :error-messages="innErrors"
                        @input="$v.inn.$touch()"
                        @blur="$v.inn.$touch()"
                        dense
                        outlined
                        v-mask="'#########'"
                        counter="9"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-3">
                    <div>
                      <v-text-field
                        label="Inps"
                        v-model="inps"
                        :error-messages="inpsErrors"
                        @input="$v.inps.$touch()"
                        @blur="$v.inps.$touch()"
                        dense
                        :counter="14"
                        minLength="14"
                        maxLength="14"
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="mt-5 card-toolbar">
                  <el-button
                    v-on:click="submit"
                    class="btn btn-primary font-weight-bolder"
                    :disabled="newPersonalLoading"
                  >
                    <span
                      v-if="!newPersonalLoading"
                      class="svg-icon svg-icon-white svg-icon-2x"
                      ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                            fill="#000000"
                          />
                        </g></svg
                      ><!--end::Svg Icon--></span
                    >
                    <i v-else class="el-icon-loading"></i>
                    Yuborish</el-button
                  >
                </div>
                <!-- <div class="form-text">yana malumotlar</div> -->
              </div>
              <!-- </div> -->
              <!-- </div> -->

              <!--end: Wizard Step 1-->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTWizard from '@/assets/js/components/wizard'

export default {
  name: 'Wizard-1',
  data: () => ({
    newPersonalLoading: false,
    regionSelect: '',
    oblastSelect: '',
    dialog: false,
    name: '',
    first_name: '',
    last_name: '',
    father_name: '',
    passport_number: '',
    phone_number: '',
    pochta_address: '',
    inn: '',
    inps: '',
    pinfl: ''
  }),
  validations: {
    first_name: {
      required
    },
    last_name: {
      required
    },
    father_name: {
      required
    },
    phone_number: {
      required
    },
    passport_number: {
      required
    },
    pochta_address: {
      required
    },
    oblastSelect: {
      required
    },
    regionSelect: {
      required
    },
    pinfl: {
      required
    },
    inn: {
      required
    },
    inps: {
      required
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hodimlar' },
      { title: 'Personal qoshish' }
    ])

    this.initWizard()
  },
  created() {
    this.$store.dispatch('getRegions')
  },
  computed: {
    first_nameErrors() {
      const errors = []
      if (!this.$v.first_name.$dirty) return errors
      !this.$v.first_name.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    last_nameErrors() {
      const errors = []
      if (!this.$v.last_name.$dirty) return errors
      !this.$v.last_name.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    father_nameErrors() {
      const errors = []
      if (!this.$v.father_name.$dirty) return errors
      !this.$v.father_name.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    phone_numberErrors() {
      const errors = []
      if (!this.$v.phone_number.$dirty) return errors
      !this.$v.phone_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    passport_numberErrors() {
      const errors = []
      if (!this.$v.passport_number.$dirty) return errors
      !this.$v.passport_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    pochta_addressErrors() {
      const errors = []
      if (!this.$v.pochta_address.$dirty) return errors
      !this.$v.pochta_address.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oblastSelectErrors() {
      const errors = []
      if (!this.$v.oblastSelect.$dirty) return errors
      !this.$v.oblastSelect.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    regionSelectErrors() {
      const errors = []
      if (!this.$v.regionSelect.$dirty) return errors
      !this.$v.regionSelect.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    pinflErrors() {
      const errors = []
      if (!this.$v.pinfl.$dirty) return errors
      !this.$v.pinfl.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    innErrors() {
      const errors = []
      if (!this.$v.inn.$dirty) return errors
      !this.$v.inn.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    inpsErrors() {
      const errors = []
      if (!this.$v.inps.$dirty) return errors
      !this.$v.inps.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    Oblast() {
      return this.$store.state.requests.allregions
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    initWizard() {
      this.$nextTick(() => {
        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })

        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    },
    submit(e) {
      e.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          first_name:
            this.first_name.charAt(0).toUpperCase() + this.first_name.slice(1),
          last_name:
            this.last_name.charAt(0).toUpperCase() + this.last_name.slice(1),
          father_name:
            this.father_name.charAt(0).toUpperCase() +
            this.father_name.slice(1),
          passport_number: this.passport_number,
          phone_number: this.phone_number.replace(/[{()}]/g, ''),
          pochta_address: this.pochta_address,
          adress_oblast: this.oblastSelect.id,
          adress_region: this.regionSelect,
          inn: this.inn,
          inps: this.inps,
          pinfl: this.pinfl
        }
        this.newPersonalLoading = true
        this.$store
          .dispatch('personalCreate', data)
          .then(() => {
            this.newPersonalLoading = false
            this.first_name = ''
            this.last_name = ''
            this.father_name = ''
            this.passport_number = ''
            this.phone_number = ''
            this.pochta_address = ''
            this.adress_oblast = ''
            this.adress_region = ''
            this.inn = ''
            this.inps = ''
            this.pinfl = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newPersonalLoading = false
            console.error(err)
          })
        console.log(data)
      }
    }
  },

  watch: {
    isLoading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
.wizard-step {
  padding: 0;
}
</style>
